import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Roboto } from 'next/font/google';
import { Arimo } from 'next/font/google';

const roboto = Roboto({
  weight: ['400', '500', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin', 'cyrillic'],
  display: 'swap',
});

const arimo = Arimo({
  weight: ['400', '500', '700'],
  style: ['normal', 'italic'],
  subsets: ['latin', 'cyrillic'],
  display: 'swap',
});

const theme = createTheme({
  typography: {
    fontFamily: `${roboto.style.fontFamily}, ${arimo.style.fontFamily}`,
    allVariants: {
      textTransform: 'none',
    },
    button: {
      fontFamily: arimo.style.fontFamily,
      textTransform: 'none',
      fontSize: '15px',
      fontWeight: 500,
      lineHeight: '22.5px',
    },
    h1: {
      fontSize: '3.5rem',
      fontFamily: roboto.style.fontFamily,
      display: 'block',
    },
    h2: {
      fontSize: '2.8rem',
      fontFamily: roboto.style.fontFamily,
      display: 'block',
    },
    h3: {
      fontSize: '1.8rem',
      fontFamily: roboto.style.fontFamily,
      display: 'block',
    },
    h4: {
      fontSize: '1.4rem',
      fontFamily: roboto.style.fontFamily,
      display: 'block',
    },
    h5: {
      fontSize: '1.2rem',
      fontFamily: roboto.style.fontFamily,
      display: 'block',
    },
    h6: {
      fontSize: '1.1rem',
      fontFamily: roboto.style.fontFamily,
      display: 'block',
    },
    body1: {
      fontFamily: arimo.style.fontFamily,
      display: 'block',
    },
    body2: {
      fontFamily: arimo.style.fontFamily,
      display: 'block',
    },
    subtitle1: {
      fontFamily: arimo.style.fontFamily,
    },
    subtitle2: {
      fontFamily: arimo.style.fontFamily,
    },
  },
  palette: {
    primary: {
      main: 'rgb(244, 200, 197)', // pink
    },
    secondary: {
      main: '#2e3039',
    },
    divider: '#4b4c53',
    common: {
      white: '#FFF7E5',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '& a': {
          color: 'inherit',
          textDecoration: 'none',
        },
        '& ul': {
          marginBlockStart: 'unset',
          marginBlockEnd: 'unset',
          paddingInlineStart: 'unset',
          listStylePosition: 'inside',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          // backgroundColor: 'rgba(46, 48, 57,0.05)',
          // backdropFilter: 'blur(25px)',
          backgroundColor: 'rgba(84, 86, 95,0.5)',
          backdropFilter: ' blur(15px)',
          // boxShadow:
          //   'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(254, 205, 211, 0.03) 0.25px 1px 0px 0px inset, rgba(3, 2, 2, 0.02) 0px 0.3px 0.3px 0px, rgba(3, 2, 2, 0.02) 0px 2.2px 2.5px -0.4px, rgba(3, 2, 2, 0.02) 0px 4.3px 4.8px -0.8px, rgba(3, 2, 2, 0.02) 0px 7.5px 8.4px -1.2px, rgba(3, 2, 2, 0.02) 0px 12.8px 14.4px -1.7px, rgba(3, 2, 2, 0.02) 0px 21px 23.6px -2.1px, rgba(3, 2, 2, 0.02) 0px 33.2px 37.4px -2.5px',
          color: 'white',
          backgroundImage: 'none',
          boxShadow: 'none',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '1248px !important',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) =>
          theme.unstable_sx({
            padding: '8px 40px',
            width: { xs: '100%', sm: 'unset' },
          }),
        contained: {
          color: 'white',
        },
        outlined: {
          borderWidth: '2px',
          backgroundColor: 'rgba(246, 122, 32, 0.05);',
          '&:hover': {
            borderWidth: '2px',
          },
        },
      },
    },
  },
});

const lightTheme = responsiveFontSizes(theme);

export default lightTheme;
