import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { PrismicLink } from '@prismicio/react';
import { PrismicNextImage } from '@prismicio/next';

const styles = {
  socialLinks: {
    display: 'flex',
    justifyContent: { xs: 'center', md: 'unset' },
    listStyle: 'none',
    gap: '15px',
  },
};

const SocialLinksList = ({ socialLinks, width = 24, height = 24 }) => {
  return (
    <Box component='ul' sx={styles.socialLinks}>
      {socialLinks.map(
        ({ social_icon: socialIcon, social_link: socialLink }) => {
          return (
            <li key={socialLink.url}>
              <PrismicLink field={socialLink}>
                <PrismicNextImage
                  field={socialIcon}
                  width={width}
                  height={height}
                />
              </PrismicLink>
            </li>
          );
        }
      )}
    </Box>
  );
};

SocialLinksList.propTypes = {
  socialLinks: PropTypes.array.isRequired,
};

export default SocialLinksList;
