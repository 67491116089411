import { PrismicNextLink } from '@prismicio/next';
import Link from 'next/link';
import { PrismicText } from '@prismicio/react';
/**
 * @typedef {import("@prismicio/client").Content.NavigationItemSlice} NavigationItemSlice
 * @typedef {import("@prismicio/react").SliceComponentProps<NavigationItemSlice>} NavigationItemProps
 * @param {NavigationItemProps}
 */
const NavigationItem = ({ slice }) => {
  if (!slice.primary.link?.url && !slice.primary.slice) return null;
  return (
    <li
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      key={slice.id}>
      <Link
        href={`${slice.primary.link.url || ''}${
          slice.primary.slice ? '#' + slice.primary.slice : ''
        }`}>
        <PrismicText field={slice.primary.name} />
      </Link>

      {/* Renders child links, if present. In future it will be on hover*/}
      {slice.items.length > 0 && (
        <ul>
          {slice.items.map((item) => {
            return (
              <li key={JSON.stringify(item)}>
                <PrismicNextLink field={item.child_link}>
                  <PrismicText field={item.child_name} />
                </PrismicNextLink>
              </li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

export default NavigationItem;
