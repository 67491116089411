'use client';
import { Box, Typography } from '@mui/material';
import Link from 'next/link';
import { useLangContext } from '@/context/langContext';
import { PrismicNextImage } from '@prismicio/next';

const styles = {
  logoBlock: {
    marginRight: { xs: '16px', md: '100px' },
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
  },
  logo: {
    fontFamily: 'monospace',
    fontSize: '0.7rem',
    lineHeight: '1rem',
    fontWeight: 700,
    letterSpacing: '0.1rem',
  },
  logoIcon: {
    display: 'flex',
    marginRight: '8px',
    backgroundColor: 'secondary.main',
    borderRight: '2px solid white',
    borderRadius: '2px',
  },
  logoText: {
    textAlign: 'left',
  },
};

const Logo = ({ logo }) => {
  const lang = useLangContext();
  return (
    <Box sx={styles.logoBlock} component={Link} href={`/${lang}`}>
      <Box sx={styles.logoIcon}>
        <PrismicNextImage field={logo} width={28} height={28} />
      </Box>
      <Box sx={styles.logoText}>
        <Typography variant='body1' noWrap sx={styles.logo}>
          OKSANA
        </Typography>
        <Typography variant='body1' noWrap sx={styles.logo}>
          KOROBANOVA
        </Typography>
      </Box>
    </Box>
  );
};

export default Logo;
