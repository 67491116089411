'use client';
import { Box, Typography } from '@mui/material';
import Link from 'next/link';
import { PrismicNextImage } from '@prismicio/next';
import { usePathname } from 'next/navigation';

const styles = {
  langBox: {
    marginRight: { xs: 'unset', md: '50px' },
    display: 'flex',
    gap: { xs: '20px', md: '15px' },
  },
  langLink: {
    display: 'flex',
    alignItems: 'center',
  },
  langcode: {
    display: 'inline',
    textTransform: 'uppercase',
  },
};

const LangSelector = ({ langs = [], lang }) => {
  const pathName = usePathname();

  const redirectedPathName = (locale) => {
    if (!pathName) return '/';
    const segments = pathName.split('/');
    segments[1] = locale;
    return segments.join('/');
  };
  return (
    <Box sx={styles.langBox}>
      {langs.map((langItem) => {
        return (
          <Box
            component={Link}
            sx={{
              ...styles.langLink,
              opacity: langItem.langcode === lang ? 1 : 0.5,
            }}
            key={langItem.langcode}
            href={redirectedPathName(langItem.langcode)}>
            <PrismicNextImage
              field={langItem.langimg}
              height={18}
              width={18}
              style={{
                borderRadius: '50%',
                marginRight: '5px',
              }}
            />
            <Typography variant='body2' component='span' sx={styles.langcode}>
              {langItem.langcode}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default LangSelector;
