'use client';
import { useMemo, useState } from 'react';
import { Box, Drawer, IconButton, List } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NavigationItem from '@/slices/NavigationItem';
import Logo from './logo';
import LangSelector from './inlineLangSelector';
import SocialLinksList from '../ui/socialLinksList';
import { useLangContext } from '@/context/langContext';

const styles = {
  menuBtn: {
    marginLeft: 'auto',
    display: { xs: 'block', md: 'none' },
  },
  ul: {
    display: { xs: 'none', md: 'flex' },
    flexGrow: 1,
    listStyle: 'none',
    gap: '25px',
    padding: 0,
    margin: 0,
  },
  mobileBlock: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '30px',
    padding: '50px 0',
  },
  mobileList: {
    '& li': {
      padding: '20px 0',
    },
  },
  mobileBottom: {
    marginTop: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
};

const MobileMenu = ({ menu, lang, logo, children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  return (
    <Box sx={styles.menuBtn}>
      <IconButton
        color='inherit'
        aria-label='open drawer'
        edge='start'
        onClick={handleDrawerToggle}>
        <MenuIcon />
      </IconButton>

      <Box component='nav'>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              minWidth: '240px',
              width: '75%',
              backgroundColor: 'rgb(46, 48, 57)',
              color: 'white',
            },
          }}>
          <Box sx={styles.mobileBlock}>
            <Logo logo={logo} />
            <List
              sx={styles.mobileList}
              onClick={() => {
                setMobileOpen(false); // close drawer on link click
              }}>
              {children}
            </List>
            <Box sx={styles.mobileBottom}>
              <SocialLinksList socialLinks={menu.social_link} />
              <LangSelector langs={menu.languages} lang={lang} />
            </Box>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

const Menu = ({ menu, logo }) => {
  const lang = useLangContext();

  const menuItems = useMemo(() => {
    return menu.slices.map((slice) => (
      <NavigationItem key={slice.id} slice={slice} />
    ));
  }, [menu]);

  return (
    <>
      {/* if is Mobile for SSR */}
      <MobileMenu menu={menu} lang={lang} logo={logo}>
        {menuItems}
      </MobileMenu>

      {/* if is Desktop for SSR */}
      <Box sx={{ display: { xs: 'none', md: 'flex' }, width: '100%' }}>
        <Box component='ul' sx={styles.ul}>
          {menuItems}
        </Box>

        <LangSelector langs={menu.languages} lang={lang} />
        <SocialLinksList socialLinks={menu.social_link} />
      </Box>
    </>
  );
};

export default Menu;
