'use client';

import { createContext, useContext } from 'react';

export const LangContext = createContext({});

export default function MainContextProvider({ initialLang, children }) {
  return (
    <LangContext.Provider value={initialLang}>{children}</LangContext.Provider>
  );
}

export function useLangContext() {
  const context = useContext(LangContext);

  if (context === null) {
    throw new Error(
      'useLangContext must be used within an LangContextProvider'
    );
  }

  return context;
}
